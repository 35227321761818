const userBehave = {
  mainPage: 'mainPage',
  invitationPage: 'invitationPage',
  takeTheAssessment: 'takeTheAssessment',
  letsGetsStarted: 'letsGetsStarted',
  letsGetsStartedCrowd: 'letsGetsStartedCrowd',
  answerToQuestion: 'answerToQuestion',
  answerToQuestionCrowd: 'answerToQuestionCrowd',
  redirectToRegisterPage: 'redirectToRegisterPage',
  registrationOnSite: 'registrationOnSite',
  deleteAccountData: 'deleteAccountData',
  copyLink: 'copyLink',
};

export default userBehave;
