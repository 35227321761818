<template>
<div>
  <div class="header">
    <!-- eslint-disable -->
    <div style="display:flex;" class="question" v-if="!hiddenHeader">
      <div class="logo-innerworks">
        <div v-if="showMainMenu">
          <img
            src="../../assets/small_logo_with_label.svg"
            alt="logo innerworks"
          />
          <img
            class="faq-icon"
            src="../../assets/person_icon.svg"
            alt="FAQ"
            @click="openPopUp"
            v-click-outside="closePopUp"
          />
          <span class="pop-up-login" v-if="showPopUp">
            <button class="login-popup" v-if="!getProfile.token"
                    @click="redirectToLogIn">Login</button>
            <button class="login-popup" v-else
                    @click="userLogOut">Logout</button>
          </span>
        </div>
        <div v-else-if="showHear1 || showHear2">
          <span class="logo-block" @click="redirectToMainPage">
            <img
              src="../../assets/small_logo.svg"
              alt="logo innerworks"
            />
            <span v-if="showHear1 || showHear2" class="header_text header_text__1 logo-text">
              Personality Assessment
            </span>
          </span>
          <img
            v-if="showHear1"
            class="faq-icon"
            src="../../assets/help.svg"
            alt="FAQ"
            @click="openFAQModal"
          />
          <FAQHeader
            :from-header="true"
            :showing-modal="isOpenModal"
            @show-modal="closeModal($event)"/>
          <WarningGoingHomeModal
            v-if="step1"
            :showing-modal="isOpenWarningModal"
            @show-modal="closeWarningModalModal($event)"/>
        </div>
      </div>
      <div class="div-header_text">
<!--        <div v-if="showHear2" class="header_text header_text__1 logo-text">-->
<!--          Personality Assessment-->
<!--        </div>-->
<!--        <p v-else-if="showHear3" class="header_text">-->
<!--          For you to access your data in future and for  us to protect it, we need some details*.-->
<!--        </p>-->
<!--        <p v-else-if="showHear4" class="header_text">-->
<!--          Move + release slider to move to the next question-->
<!--        </p>-->
<!--        <p v-else-if="showHear5" class="header_text">-->
<!--          Why not start the process for yourself? It takes less than 2 minutes ...-->
<!--        </p>-->
      </div>
    </div>
  </div>
</div>
</template>

<script>

import { mapGetters } from 'vuex';
import FAQHeader from '@components/FrequentlyAskedQuestions/FAQHeader.vue';
import WarningGoingHomeModal from '@components/Modals/WarningGoingHomeModal.vue';

export default {
  name: 'Header',
  components: {
    WarningGoingHomeModal,
    FAQHeader,
  },
  computed: {
    ...mapGetters({
      getProfile: 'auth/getProfile',
      textHeader: 'showComponent/textHeader',
      showDetailHeader2: 'showComponent/showDetailHeader2',
      showHear1: 'showComponent/showHear1',
      showHear2: 'showComponent/showHear2',
      showHear3: 'showComponent/showHear3',
      showHear4: 'showComponent/showHear4',
      showHear5: 'showComponent/showHear5',
      hiddenHeader: 'showComponent/hiddenHeader',
      linkCopy: 'showComponent/linkCopy',
      showButonRegister: 'showComponent/showButonRegister',
      step1: 'showComponent/step1',
      startedPage: 'showComponent/startedPage',
    }),
  },
  data: () => ({
    showPopUp: false,
    showMainMenu: false,
    isOpenModal: false,
    isOpenWarningModal: false,
  }),
  mounted() {
    if (this.$router.currentRoute.name === 'main') {
      this.$store.dispatch('showComponent/mainPage');
      this.showMainMenu = true;
    }
  },
  methods: {
    closeModal(value) {
      this.isOpenModal = value;
    },
    closeWarningModalModal(value) {
      this.isOpenWarningModal = value;
    },
    openFAQModal() {
      this.isOpenModal = true;
    },
    backToAnonymous() {
      this.$store.dispatch('showComponent/gotToStepInitial');
    },
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push({ name: 'main' });
    },
    headerRegistration() {
      const route = this.$route.path.split('/').filter(x => x !== '');
      return route[0] === 'login';
    },
    redirectToRegistration() {
      this.$router.push({
        name: 'main',
        params: {
          slide: 3,
        },
      });
    },
    redirectToLogIn() {
      this.$router.push('login');
    },
    userLogOut() {
      this.$store.dispatch('auth/logout');
    },
    openPopUp() {
      if (this.showPopUp) {
        this.showPopUp = false;
        return;
      }
      this.showPopUp = true;
    },
    closePopUp() {
      this.showPopUp = false;
    },
    register() {
      this.$router.push({
        name: 'registration',
      });
    },
    redirectToMainPage() {
      if (!this.step1) {
        this.$router.push({ name: 'main' });
      }
      this.isOpenWarningModal = true;
    },
  },
};

</script>

<style lang="scss">
  .header-detail {
    text-align: center;
  }
  .header-detail_special_text {
    font-weight: bold;
  }
  .header_text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 16px;
  }
  .header{
    top: 0;
    left: 0;
    width: 100%;
    /*background-color: #F3F4F6;*/
    justify-content: space-between;
    align-items: center;

  }
  .header_text__1 {
    color: #FF5151;
  }
  .header__logo{
    width: 7vh;
    height: 7vh;
    @media (max-height: $xxsMaxHeight) {
      width: 5vh;
      height: 5vh;
    }
  }
  .question {
    height: 100%;
  }
  .logo-innerworks {
    padding-left: 25px;
    margin-top: 20px;
    width: 100%;
    position: relative;
    margin-bottom: 20px;
  }
  .logo-text {
    font-family: $newDefaultFont;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: $txtColorNewDesignMain;
    padding-left: 9px;
    padding-top: 5px;
  }
  .faq-icon {
    float: right;
    margin-right: 25px;
    border-left: 1px solid #E9E9E9;
    padding-left: 20px;
    cursor: pointer;
  }
  .login-popup {
    width: 111px;
    padding-right: 25px;
    position: relative;
    background: #ffffff;
    border-radius: 15px;
    border: 1px solid #E9E9E9;
    height: 40px;
    box-shadow: 0 14px 14px rgba(0, 0, 0, 0.09);
  }
  .login-popup:before {
    content: "";
    position: absolute;
    bottom: 100%;
    right: 20px;
    border: 9px solid transparent;
    border-bottom-color: #ffffff;
    margin-right: -1px;
  }
  .pop-up-login {
    position: absolute;
    right: 13px;
    top: 45px;
  }
  .logo-block {
    cursor: pointer;
  }
  @media (max-width: 356px) {
    .logo-text {
      font-size: 15px !important;
    }
  }
  @media (max-width: 325px) {
    .logo-text {
      font-size: 13px !important;
    }
  }
  @media (max-width: 301px) {
    .logo-text {
      font-size: 11px !important;
    }
  }
</style>
