<template>
  <v-dialog
    v-model="dialog"
    width="480"
    content-class="custom-header-faq-dialog"
  >
    <v-card color="#FAF9F9">
      <v-btn
        class="arrow-to-back"
        text
        @click="dialog = false"
      >
        <img :src="arrowLeft"  alt="arrow-left"/>
      </v-btn>
      <div>
        <div class="dialog-title-faq">Need Some Help?</div>
        <hr/>
      </div>
      <v-card-text>
        <FAQOnboarding :show-title-full="fromHeader"/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import arrowLeft from '@assets/arrow_left.svg';
import FAQOnboarding from '@components/FrequentlyAskedQuestions/FAQOnboarding.vue';

export default {
  name: 'FAQHeader',
  components: { FAQOnboarding },
  data: () => ({
    dialog: true,
    arrowLeft,
  }),
  props: {
    fromHeader: {
      type: Boolean,
      defaultValue: false,
    },
    showingModal: {
      type: Boolean,
    },
  },
  watch: {
    showingModal: {
      immediate: true,
      handler(val) {
        this.dialog = val;
      },
    },
    dialog(value) {
      this.$emit('show-modal', value);
    },
  },
};
</script>

<style lang="scss" scoped>
  .custom-header-faq-dialog {
    overflow: auto;
    z-index: 10;
  }
  .dialog-title-faq {
    font-family: $newDefaultFont;
    font-weight: bold;
    margin-top: 75px;
    font-size: 32px;
    line-height: 36px;
    text-align: center;
    color: #320959;
  }
  .dialog-subtitle-faq {
    font-family: $newDefaultFont;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #320959;
  }
</style>

<style lang="scss">
  .custom-header-faq-dialog .question__content {
    padding: 0 12px;
  }
  .custom-header-faq-dialog {
    max-height: 100% !important;
    margin: 0;
    font-family: $newDefaultFont;
  }
</style>
