<template>
  <!-- <div> -->
  <Content>
    <div :class="{'faq_onboarding': !showTitleFull, 'header-faq': showTitleFull}">
      <div class="faq-title" :style="{'font-weight': showTitleFull ? '300': ''}">
        {{showTitleFull ? 'FAQ\'s' :'Frequently Asked Questions' }}</div>
      <v-expansion-panels
        v-model="activeItem"
        tile accordion>
        <v-expansion-panel
          v-for="(item, j) in categories"
          :key="j"
        >
          <v-expansion-panel-header
            :class="{ 'sub-title': j === activeItem }"
            hide-actions
            class="question__content"
          >
            <div class="expand-header">
              <span class="question__content">{{item}}</span>
              <img :src="minusIcon" alt="icon-minus" v-if="j === activeItem"/>
              <img :src="plusIcon" alt="icon-plus" v-else/>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content
            class="question__answer"
          >
            <span v-html="item.answer">{{item}}</span>
            <ExpandFAQ :fa-question-list="faQuestionList[item]"/>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </Content>
</template>

<script>
import plusIcon from '@assets/plus-icon.svg';
import minusIcon from '@assets/minus-icon.svg';
import ExpandFAQ from '@components/FrequentlyAskedQuestions/ExpandFAQ.vue';

export default {
  components: {
    ExpandFAQ,
  },
  data() {
    return {
      plusIcon,
      minusIcon,
      activeItem: null,
      faQuestionList: {},
      categories: [],
      dialog: true,
    };
  },
  props: {
    showTitleFull: {
      type: Boolean,
      defaultValue: true,
    },
  },
  created() {
    this.$api.faq.fetchFAQ().then((data) => {
      this.categories = data.groupOrder;
      const result = {};
      data.groupOrder.forEach((x) => {
        result[x] = data.faQuestionList.filter(y => y.finalCategories === x);
      });
      this.faQuestionList = result;
    });
  },
};
</script>

<style lang="scss">
  .faq_onboarding {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
  .header-faq {
    background-color: #faf9f9 !important;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    overflow-y: auto;
    width: 100%;
    .v-sheet {
      overflow-y: scroll;
    }
    .v-expansion-panel-header {
      border-radius: 30px;
    }
    .v-expansion-panel {
      border: 1px solid #F3F4F6;
      border-radius: 15px;
      overflow: hidden;
      margin-bottom: 10px;
    }
    .question__answer {
      display: flex;
    }
    .expand-header {
      padding-top: 25px;
      padding-bottom: 16px;
      background: white;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      img {
        padding-right: 10px;
      }
    }
  }
</style>
