<template>
  <v-expansion-panels
    v-model="activeItem"
    tile accordion
    class="mb-6">
    <v-expansion-panel
      v-for="(item, j) in faQuestionList"
      :key="j"
    >
      <v-expansion-panel-header
        :class="{ 'sub-title': j === activeItem }"
        hide-actions
        class="question__content"
      >
        <div class="expand-header">
          <span class="question__content">{{item.question}}</span>
          <img :src="minusIcon" alt="icon-minus" v-if="j === activeItem"/>
          <img :src="plusIcon" alt="icon-plus" v-else/>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content
        class="question__answer"
      >
        <span v-html="item.answer">{{item.answer}}</span>
        <ExpandFAQ :discharge="closeAll" :fa-question-list="item.faQuestionExpand"/>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import plusIcon from '@assets/plus-icon.svg';
import minusIcon from '@assets/minus-icon.svg';

export default {
  name: 'ExpandFAQ',
  props: {
    faQuestionList: {
      type: [Object, Array],
    },
    discharge: {
      type: Boolean,
    },
  },
  watch: {
    activeItem() {
      this.closeAll = !this.closeAll;
    },
    discharge() {
      this.activeItem = this.discharge;
    },
  },
  data() {
    return {
      plusIcon,
      minusIcon,
      activeItem: null,
      nestedActiveItem: null,
      closeAll: false,
    };
  },
};
</script>

<style lang="scss" scoped>

</style>
